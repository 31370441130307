import React from "react"
import Helmet from 'react-helmet'
import {graphql, Link} from 'gatsby'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Container from '@material-ui/core/Container'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import Layout from '../layouts'
import HeaderNavbar from '../components/HeaderNavbar'
import Slider from 'react-slick'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center'
  },
  alert: {
    backgroundColor: '#0E6FB7',
    color: '#FFF',
    paddingBottom: 20,
    paddingLeft: 16,
    paddingRight: 16,
    paddingTop: 20,
    [theme.breakpoints.up('md')]: {
      paddingLeft: 100,
      paddingRight: 100,
    },
  },
  slideBody: {
    minHeight: 230,
    padding: 16,
    [theme.breakpoints.up('sm')]: {
      minHeight: 'inherit',
      paddingLeft: 30,
      paddingRight: 30,
      position: 'absolute',
      top: '50%',
      transform: 'translateY(-50%)',
      width: 365,
    },
    [theme.breakpoints.up('lg')]: {
      minHeight: 'inherit',
      paddingLeft: 60,
      paddingRight: 60,
      position: 'absolute',
      top: '50%',
      transform: 'translateY(-50%)',
      width: 700,
    },
  },
  slideImage: {
    objectFit: 'cover',
    objectPosition: '85%',
    height: 260,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      height: 260,
      objectPosition: 'inherit',
    },
    [theme.breakpoints.up('md')]: {
      height: 310,
      objectPosition: 'inherit',
    },
  },
}))

export default function Home({data}) {
  const classes = useStyles()
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.up('md'))
  const settings = {
    arrows: false,
    dots: true,
    infinite: true,
    speed: 1500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4500
  }
  const slideshows = [
    {
      title: '"Custom" is our speciality.',
      subtitle: 'Bring your ideas to life with our custom printing.',
      image: 'https://media.graphcms.com/9VwoFAl8Qza8t93cxe70',
      color: '#000',
      cta: null
    },
    {
      title: 'Social Distancing Signs',
      subtitle: 'Let our experts design, print, install or deliver everything to you...',
      image: 'https://media.graphcms.com/KlKpAEqoSjammrkcN56u',
      color: '#000',
      cta: null
    },
    {
      title: 'Product Design Tool',
      subtitle: 'Our new website allows you to design online.',
      image: 'https://media.graphcms.com/B9EDG2EhQPm3FskUwAlQ',
      color: '#FFF',
      cta: null
    }
  ]

  return (
      <Layout>
        <Helmet>
          <title>Signs, Banners, and Graphics | Signxperts</title>
        </Helmet>
        <HeaderNavbar />
        <div className={classes.alert}>
          <strong>COVID-19 / Coronavirus Resources</strong> - We're trying to help flatten the curve! Check out our
          free printable templates, custom face masks, custom neck gaiters, and custom signage and resources!
        </div>
        <Box marginBottom={5}>
          <Slider {...settings}>
            {slideshows.map((slideshow, index) => (
                <div key={index} style={{position: 'relative'}}>
                  <img src={slideshow.image} className={classes.slideImage} alt={slideshow.title} />
                  <div className={classes.slideBody}>
                    <Box marginBottom={3}>
                      <Typography variant={isTablet ? 'h2' : 'h4'} style={{color: isTablet ? slideshow.color : '#000', fontWeight: 'bold'}} gutterBottom dangerouslySetInnerHTML={{__html: slideshow.title}} />
                      <Typography variant={'h5'} style={{color: isTablet ? slideshow.color : '#000'}} gutterBottom dangerouslySetInnerHTML={{__html: slideshow.subtitle}} />
                    </Box>
                    {slideshow.cta && (
                        <Button variant={'contained'} color={'primary'} href={slideshow.cta.url}>
                          {slideshow.cta.label}
                        </Button>
                    )}
                  </div>
                </div>
            ))}
          </Slider>
        </Box>
        <Container className='pageContent'>
          <Grid container spacing={3}>
            {data.gcms.collections.map(({id, name, image, slug}, index) => (
                <Grid key={index} item xs={12} sm={4} style={{marginBottom: 40}}>
                  <Link to={slug}>
                    <img src={image.url} style={{height: 250, objectFit: 'cover', width: '100%'}} alt={name} />
                    <Paper className={classes.paper}>
                      <Typography variant={'h6'}>
                        {name}
                      </Typography>
                    </Paper>
                  </Link>
                </Grid>
            ))}
          </Grid>
        </Container>
      </Layout>
  )
}

export const query = graphql`
  query MyQuery {
    gcms {
      collections(stage: DRAFT) {
        id
        name
        image {
          url
        }
        slug
      }
    }
  }
`
